import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

const isLocal: boolean = /localhost/.test(document.location.host);
if (!isLocal) {
  enableProdMode(); // Enable Prod Mode if it is not using localhost
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

