import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'searchengine', pathMatch: 'full' },
  { path: 'searchengine', loadChildren: './pages/searchengine/searchengine.module#SearchengineModule' },
  { path: 'account', loadChildren: './pages/account/account.module#AccountModule' },
  { path: '**', redirectTo: 'searchengine', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
