import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ConfigService {

    public searchServerApiUrl = '';
    public enableDebug = true;

    constructor() { }
}
