import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { tap, map } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { ConfigService } from '../services/configuration.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    public router: Router,
    private _configService: ConfigService,
    private toastrService: ToastrService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Prefixes all requests with 'this._configService.searchServerApiUrl'.
    if (!/^(http|https):/i.test(req.url)) {
      req = req.clone({ url: this._configService.searchServerApiUrl + req.url });

      if (this._configService.enableDebug) {
        console.log('Configuration: %o \nRequest: %o', this._configService, req);
      }
    }
    // add authentication Bearer if need
    let authHeader = req.headers.get('Authorization');
    let authReq = req;

    if (!authHeader && this._authService.isAuthenticated()) {
      const credentials = this._authService.getCredentials();
      if (credentials) {
        authHeader = credentials.token;
      // Clone the request to add the new header.
      if (authHeader) {
          authReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + authHeader) });
        }
      }
    }

    // send the newly created request
    return next.handle(authReq).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this._authService.logout();
          this.toastrService.error('Your session timed out, please log in again.', 'Time Out');
          this.router.navigate(['/account/login']);
        }
      }
    }));
    return next.handle(authReq);

  }
}

