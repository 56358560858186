import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User, ResetPasswordModel } from '../shared/models/user/user.model';
import { Observable } from 'rxjs/internal/Observable';
import { UserPageList } from '../shared/models/user/user.page.list';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }

  register(user: User): Observable<User> {
    return this.http.post<User>('/api/users', user);
  }

  login(credentials): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
        // tslint:disable-next-line:max-line-length
      })
    };
    return this.http.post<any>('/api/auth/login', credentials, httpOptions);
  }

  getUsers(params): Observable<UserPageList> {
    const url = '/api/users' + (params.toString().length > 0 ? ('?' + params.toString()) : '');
    return this.http.get<UserPageList>(url);
  }

  countUsers(): Observable<number> {
    return this.http.get<number>('/api/users/count');
  }

  addUser(user: User): Observable<User> {
    return this.http.post<User>('/api/users', user);
  }

  getUser(id: number): Observable<User> {
    return this.http.get<User>(`/api/users/${id}`);
  }

  checkEmail(email: string): Observable<object> {
    return this.http.get<User>(`/api/users/isExist?email=${email}`);
  }

  editUser(user: User): Observable<object> {
    return this.http.put(`/api/users/${user.id}`, user);
  }

  resetPassword(resetPasswordModel: ResetPasswordModel): Observable<ResetPasswordModel> {
    return this.http.post<ResetPasswordModel>(`/api/users/resetPassword`, resetPasswordModel);
  }

  deleteUser(id: number): Observable<object> {
    return this.http.delete(`/api/users/${id}`);
  }
}
