import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Login } from '../shared/models/login.model';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';

const credentialsKey = 'viSearch2credentials';

export interface Credentials {
  // Customize received credentials here
  email: string;
  name: string;
  token: string;
  expire: number;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _credentials: Credentials | null;

  constructor(private userService: UserService) {
  }

  public isAuthenticated(): boolean {
    const savedCredentials = this.getCredentials();
    // Check whether the current time is past the
    // access token's expiry time
    const helper = new JwtHelperService();
    if (savedCredentials === undefined || savedCredentials === null || savedCredentials.token === undefined) {
      return false;
    }
    const date = new Date(0);
    date.setUTCSeconds(savedCredentials.expire);
    if (date === null) {
        return true;
    }
    return (date.valueOf() > new Date().valueOf());
  }

  public getCredentials(): Credentials {
    return JSON.parse(localStorage.getItem(credentialsKey));
  }

  login(loginInfo: Login) {
    // Replace by proper authentication call
    return this.userService.login(loginInfo)
    .pipe(
      map(
        res => {
          if (res.token) {
            const tokenData = {
              email: loginInfo.email,
              name: res.user.name,
              token: res.token,
              expire: res.expire
            };
            this.setCredentials(tokenData, loginInfo.remember);
            return of(tokenData);
          } else {
            throw new Error('Invalid Credentials');
          }
        },
      ),
    );
  }

  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.setCredentials();
    return of(true);
  }

  private setCredentials(credentials?: Credentials, remember?: boolean) {
    if (credentials) {
      // const storage = remember ? localStorage : sessionStorage;
      localStorage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      // sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }
}
