import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  constructor(private auth: AuthService,
    private router: Router) { }

  ngOnInit() {
  }
  logout() {
      this.auth.logout();
      this.router.navigate(['account/login']);
  }
  isLogin() {
    return this.auth.isAuthenticated();
  }
  getUserName() {
    if (this.isLogin()) {
      return this.auth.getCredentials().name;
    }
    return '';
  }
}
